import React, { useState, useEffect, useReducer } from 'react';
import './Account.css';
import { useTranslation } from 'react-i18next';
import { Box, TextField, Typography } from '@mui/material';
import useAuth from '../../hooks/useAuth'
import useFetchPrivate from '../../hooks/useFetchPrivate';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const Account = () => {
  const [, forceUpdate] = useReducer(x => x + 1, 0); //for forced re-render
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    role: '',
    licenseType: '',
    licenseEx: '',
    customerID: '',
    subscription: '',
    licenseKey: ''
  });
  const { auth } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const fetchPrivate = useFetchPrivate();


  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const response = await fetchPrivate(`${process.env.REACT_APP_BACKEND}/account`, {
          method: 'GET'
        });

        if (response.ok) {
          const data = await response.json();
          console.log("data:", data);
          setUserData({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            company: data.company,
            role: data.role,
            licenseType: data.licenseType || "",
            licenseEx: data.licenseEx ? new Date(data.licenseEx).toLocaleDateString() : "",
            subscription: data.subscription,
            customerID: data.customerID,
            licenseKey: data.licenseKey
          });
          console.log("user role:", data.role);
        } else {
          console.error('Failed to fetch account data, status:', response.status);
        }
      } catch (error) {
        console.error('Error fetching account data:', error);
      }
    };

    fetchAccountData();
  }, [navigate, forceUpdate]);

  const handleProfileUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/account/update-profile`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();
      alert('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    if (data.get('new-password') !== data.get('confirm-password')) {
      toast.error(t('falseMatchPassword'));
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/account/change-password`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currentPassword: data.get('current-password'),
          newPassword: data.get('new-password'),
          confirmPassword: data.get('confirm-password'),
        }),
      });

      const responseData = await response.json();
      if (!response.ok) {
        if (response.status === 401) {
          toast.error(t('wrongPassword')); // Incorrect current password
        } else if (response.status === 400) {
          toast.error(t('falseMatchPassword')); // Backend validation of new passwords not matching
        } else {
          toast.error(t('defaultError')); // Default error case for other status codes
        }
      } else {
        toast.success(t('changedPassword')); // Success message
      }
    } catch (error) {
      console.error('Error changing password:', error);
    }
  };

  const handleCancelSub = async () => {
    try {
      // Send a PATCH request to cancel the subscription
      const response = await fetch(`${process.env.REACT_APP_BACKEND}/account/cancel-sub`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${auth?.AuthToken}`,
          'Content-Type': 'application/json',
        },
      });

      // Check if the response is not ok, handle the error
      if (!response.ok) {
        const errorMessage = await response.json();
        throw new Error(`Error cancelling subscription: ${errorMessage}`);
      }

      setUserData(prevState => ({
        ...prevState,
        subscription: false,
      }));
      console.log("Subscription cancelled successfully");
      forceUpdate();
    } catch (error) {
      // Log the error with more details
      console.error("Error cancelling the subscription: ", error.message);
    }
  };


  const handleChangeSub = async () => {
    navigate('/change-sub')
  };


  return (
    <Box className="account">
      <Typography variant="h3" className="page-heading">{t('accountHeading')}</Typography>

      <div className="section">
        <Typography variant="h5">{t('profileInfo')}</Typography>
        <form onSubmit={handleProfileUpdate}>
          <div className="form-group">
            <label htmlFor="firstName">{t('firstName')}:</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={userData.firstName}
              onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="lastName">{t('lastName')}:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={userData.lastName}
              onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">{t('email')}:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={userData.email}
              onChange={(e) => setUserData({ ...userData, email: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="company">{t('company')}:</label>
            <input
              type="text"
              id="company"
              name="company"
              value={userData.company}
              readOnly
            />
          </div>
          <button type="submit">{t('updateProfile')}</button>
        </form>
      </div>

      <div className="section">
        <Typography variant="h5">{t('security')}</Typography>
        <form onSubmit={handlePasswordChange}>
          <div className="form-group">
            <label htmlFor="current-password">{t('currentPassword')}</label>
            <input type="password" id="current-password" name="current-password" required />
          </div>
          <div className="form-group">
            <label htmlFor="new-password">{t('newPassword')}</label>
            <input type="password" id="new-password" name="new-password" required />
          </div>
          <div className="form-group">
            <label htmlFor="confirm-password">{t('confirmPassword')}</label>
            <input type="password" id="confirm-password" name="confirm-password" required />
          </div>
          <button type="submit">{t('changePassword')}</button>
        </form>
        {/* <br />
        <button onClick={() => window.location.href = '/enable-2fa'} style={{ backgroundColor: '#27ae60' }}>
          {t('enable2FA')}
        </button> */}
      </div>

      {userData.role === 'admin' && (
        <div className="section">
          <Typography variant="h5">{t('subscription')}</Typography>
          <p>{t('currentPlan')} <strong>{userData.licenseType}</strong></p>
          <p>{userData.licenseType === 'Free' || userData.subscription !== true ? t('subscriptionExpires') : t('nextBillingDate')} {userData.licenseEx}</p>
          <button style={{
            backgroundColor: '#cccccc',
            color: '#666666',
            border: '1px solid #999999',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'not-allowed',
            pointerEvents: 'none',
            fontWeight: 'bold'
          }}

          // onClick={handleChangeSub}

          >{t('upgradePlan')}<br />{t('comingSoon')}</button>
          {userData.licenseType !== 'Free' && userData.subscription == true && (<button onClick={handleCancelSub} className="cancel-button">{t('cancelSubscription')}</button>)}
        </div>
      )}

      <div className='section'>
        <Typography variant='h5'>{t('customerID')}</Typography>
        <TextField
          variant='outlined'
          value={userData.customerID}
          fullWidth
          InputProps={{
            style: {
              color: 'white',
              backgroundColor: '#323769',
              marginBottom: '20px'
            }
          }} />
        <Typography variant='h5'>{t('licenseKey')}</Typography>
        <TextField
          variant='outlined'
          value={userData.licenseKey}
          fullWidth
          InputProps={{
            style: {
              color: 'white',
              backgroundColor: '#323769',
              marginBottom: '20px'
            }
          }} />
      </div>

      {/* <div className="section">
        <Typography variant="h5">{t('dataPrivacy')}</Typography>
        <button onClick={() => window.location.href = '/download-data'}>{t('downloadData')}</button>
        <button onClick={() => window.location.href = '/delete-account'} className="cancel-button">{t('deleteAccount')}</button>
      </div> */}
    </Box>
  );
};

export default Account;
