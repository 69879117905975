import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';


const generateEnhancedPDF = async (reportTitle, agentId, chartData, chartInstance, timeFrame, refreshFrequency, openAIReport, AverageAppLatency, ExpectedAppLatency, ExpectedNetStab, ExpectedPacketLoss, appNetChartData, throughputChartData, lifbeChartData) => {
  const pdf = new jsPDF();
  const pageWidth = pdf.internal.pageSize.width;
  const pageHeight = pdf.internal.pageSize.height;
  const margin = 20;
  let pageNumber = 1;
  let yPosition = margin;


  const addHeader = () => {
    addWrappedText(reportTitle || 'Latency Report ', 18, true);
    addWrappedText(`Date: ${format(new Date(), 'yyyy-MM-dd HH:mm:ss')}`, 10);
    addWrappedText(`Agent ID: ${agentId} | Time Frame: ${timeFrame} | Refresh Frequency: ${refreshFrequency}`, 10);
    pdf.line(margin, yPosition + 1, pageWidth - margin, yPosition + 1);
    yPosition += 20; // Add a small gap below the line for spacing
  };

  // Helper function to add footers
  const addFooter = (pageNumber) => {
    pdf.setFontSize(10);
    pdf.text(`Page ${pageNumber}`, pageWidth - margin - 10, pageHeight - 10);
    pageNumber++;
  };
  
  const addEnhancedChart = async (chartInstance, title) => {
    const chartWidth = chartInstance.width; // Original chart width
    const chartHeight = chartInstance.height; // Original chart height
    const aspectRatio = chartWidth / chartHeight;
  
    const pdfChartWidth = pageWidth - 2 * margin; // Full width for PDF
    const pdfChartHeight = pdfChartWidth / aspectRatio; // Maintain aspect ratio
  
    checkAndAddPage(pdfChartHeight + 15);
  
    await new Promise((resolve) => {
      setTimeout(() => {
        const chartImage = chartInstance.toBase64Image();
        const centerX = (pageWidth - pdfChartWidth) / 2; // Center align chart
        pdf.addImage(chartImage, 'PNG', centerX, yPosition, pdfChartWidth, pdfChartHeight);
        yPosition += pdfChartHeight + 5;
  
        // Add centered caption below the chart
        pdf.setFontSize(9);
        pdf.setFont(undefined, 'italic');
        pdf.text(title, pageWidth / 2, yPosition, { align: 'center' });
        yPosition += 20; // Space after caption
  
        resolve();
      }, 500); // Allow chart rendering updates
    });
  };

  const addTable = (expextedAppLatency, AverageAppLatency) => {

    const headers = [["KPI", "Parameter", "Expected Levels", "Average Levels"],];

    const data = [
      ["Application Latency", "ms", expextedAppLatency, Math.round(AverageAppLatency * 1000)/1000],
      ["Connectivity Stability", "%", ExpectedNetStab, "TBD"],
      ["Packet Loss Rate", "%", ExpectedPacketLoss, "TBD"],
  ];

  checkAndAddPage(35);

  pdf.autoTable({
    head: headers,
    body: data,
    theme: 'grid',
    startY: yPosition
  });

  yPosition = pdf.lastAutoTable.finalY + 15;


}


  // Add logo to the top right of the first page
  const logoWidth = 128 / 4; // Reduce size by 1/4 to fit better on the page
  const logoHeight = 128 / 4;
  const logoX = pageWidth - margin - logoWidth;
  const logoY = margin - 15;
  pdf.addImage('favicon_logo.png', 'PNG', logoX, logoY, logoWidth, logoHeight);

  // Helper function to add a new page if needed
  const checkAndAddPage = (requiredHeight) => {
    if (yPosition + requiredHeight > pageHeight - margin) {
      addFooter(pageNumber); // Add footer to the current page before moving to the next
      pdf.addPage();
      yPosition = margin; // Reset yPosition for the new page
      pageNumber++; // Increment page number only after moving past the page
      return true
    }
    return false
  };

  // Helper function to add text with word wrap
  const addWrappedText = (text, fontSize, isBold = false) => {

    if(isBold){
        if(checkAndAddPage(60)){
            addWrappedText(text, fontSize, isBold)
        }else{
            pdf.setFontSize(fontSize);
            pdf.setFont(undefined, isBold ? 'bold' : 'normal');
            const lines = pdf.splitTextToSize(text, pageWidth - 2 * margin);
            pdf.text(lines, margin, yPosition);
            yPosition += lines.length * fontSize * 0.5 + 2;
        }
    
    
    }
    else{
        pdf.setFontSize(fontSize);
        let fontSizeUsed = pdf.getFontSize();
        const scaleFactor = pdf.internal.scaleFactor;
      
        // Step 1: Split the text into bold and normal fragments
        const fragments = text.split(/(\*\*.*?\*\*)/); // Match text wrapped in **
        let xPosition = margin; // Start at left margin
        const spaceWidth = pdf.getStringUnitWidth(' ') * fontSizeUsed / scaleFactor; // Calculate space width
        const maxWidth = pageWidth - margin; // Max line width for wrapping
      
        // Step 2: Loop through each fragment and render line by line
        fragments.forEach((fragment) => {
          const isBoldFragment = fragment.startsWith('**') && fragment.endsWith('**');
          const cleanFragment = isBoldFragment ? fragment.slice(2, -2) : fragment; // Remove ** markers




          // Split the fragment into words
          const words = cleanFragment.split(/\s+/);
           
          words.forEach((word, index) => {
           

            pdf.setFont(undefined, isBoldFragment ? 'bold' : 'normal')


            const wordWidth = pdf.getStringUnitWidth(word) * fontSizeUsed / scaleFactor;

            // Check if the word fits in the current line
            if (xPosition + wordWidth > maxWidth || yPosition + 40 > pageHeight) {
              // Step 3: Move to the next line if the word doesn't fit
              yPosition += fontSize * 0.7; // Add line height (adjusted for spacing)
              checkAndAddPage(25); // Check if a new page is needed
              xPosition = margin; // Reset xPosition to the margin
            }
    

            // Step 4: Set font style and render the word
            ;
            pdf.text(word, xPosition, yPosition);
            xPosition += wordWidth ;
      
            // Add space after the word unless it's the last word
            if (index !== words.length - 1) {
              xPosition += spaceWidth;
            }
          });
        });
      
        // Step 5: Adjust yPosition after the entire text block
        yPosition += fontSize * 1.1 ; // Add spacing after the text block
          };
          
    
  };
  



  // Title and metadata

  addHeader();

  // Parse and add OpenAI report sections
  const fullReport = openAIReport.split('\nNetwork Performance Analysis Report')[0].substring(2); // Only process the first occurrence
  const sections = fullReport.split('\n## ').map(section => section.trim());
  
  for (const section of sections) {
    const [title, ...content] = section.split('\n');
    if (!title) continue;

    checkAndAddPage(50);
    addWrappedText(title, 16, true);


    // Update dataset labels
    chartInstance.data.datasets.forEach((dataset) => {
      const labelMapping = {
        "HTTP Latency": "HTTP",
        "HTTPS Latency": "HTTPS",
        "TCP Latency": "TCP",
        "UDP Latency": "UDP",
        "ICMP Latency": "ICMP",
        "TWAMP Latency": "TWAMP"
      };
      if (labelMapping[dataset.label]) {
        dataset.label = labelMapping[dataset.label];
      }
    });

    
    chartInstance.options.plugins.legend.labels.padding = 19; // Adjust as needed

    // Trigger a full chart update to apply changes
    chartInstance.update();

    // Add relevant chart and table
    if (title.includes('Application and Network Latency')) {
      await addEnhancedChart(appNetChartData.chartInstance, 'Application and Network Latency Chart');
    } else if (title.includes('Protocol Latency')) {
      await addEnhancedChart(chartInstance, 'Protocol Latency Chart');
    } else if (title.includes('Throughput Performance')) {
      await addEnhancedChart(throughputChartData.chartInstance, 'Throughput Chart');
      await addEnhancedChart(lifbeChartData.chartInstance, 'LIFBE Throughput Chart');
    }else if (title.includes('Service Level Monitoring')) {
      addTable(ExpectedAppLatency, AverageAppLatency);
    }

    // Add section content
    content.forEach(paragraph => {
      if (paragraph.trim().startsWith('-')) {
        addWrappedText('• ' + paragraph.trim().substring(1), 10);
      }
      else {
        addWrappedText(paragraph.trim(), 10);
      }
    });

    // Add a small gap between sections
    yPosition += 10;
    
  }
  addFooter(pageNumber);
  return pdf;
};

export default generateEnhancedPDF;
