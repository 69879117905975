import OpenAI from "openai";
import i18n from 'i18next';
import { interval } from "date-fns";

const API_KEY = process.env.REACT_APP_OPENAI_KEY;

const API_URL = process.env.REACT_APP_INFLUX_API;
const HEADERS = {
  'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
  'Accept': 'application/csv',
  'Content-type': 'application/vnd.flux'
};

const openai = new OpenAI({
  apiKey: API_KEY,
  dangerouslyAllowBrowser: true,
});

function createQuery(field, agentId, customerId) {
  return `
    import "regexp"
    from(bucket: "LatenceTech")
      |> range(start: -1w)
      |> filter(fn: (r) =>
        r._measurement == "metadata_result" and
        r.agentID == "${agentId}" and
        r.customerID == "${customerId}" and
        r._field == "${field}"
      )
      |> last()
      |> pivot(rowKey:["_time"], columnKey: ["_field"], valueColumn: "_value")
      |> drop(columns: ["_time"])
  `;
}

async function fetchMetadataField(field, agentId, customerId) {
  try {
    const response = await fetch(API_URL, {
      method: "POST",
      headers: HEADERS,
      body: createQuery(field, agentId, customerId)
    });

    if (response.ok) {
      const csvData = await response.text();
      const lines = csvData.trim().split('\n');
      if (lines.length > 1) {
        const columns = lines[1].split(',');
        return columns[columns.length - 1].trim();
      }
    } else {
      console.error(`Failed to fetch ${field}:`, response.status);
    }
  } catch (error) {
    console.error(`Error fetching ${field}:`, error);
  }
  return "Unknown";
}
async function fetchHardwareAndNetworkType(agentId, customerId) {
  const [hardware, networkType, expextedAppLatency, expectedStability, expectedPacketLoss] = await Promise.all([
    fetchMetadataField("hardware", agentId, customerId),
    fetchMetadataField("networkType", agentId, customerId),
    fetchMetadataField("expectedLatency", agentId, customerId),
    fetchMetadataField("expectedStability", agentId, customerId),
    fetchMetadataField("expectedLoss", agentId, customerId),

  ]);

  return { hardware, networkType, expextedAppLatency, expectedStability, expectedPacketLoss};
}

// new fonnction that compresses the data by taking every nth data point

// Helper function to convert ISO timestamp to local time format
function formatToLocalTime(isoString) {
  const date = new Date(isoString);
  return date.toLocaleTimeString(undefined, {weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true });
}

function compressData(data, maxPoints) {
  const { labels, datasets } = data;
  const totalPoints = labels.length;
  const step = Math.ceil(totalPoints / maxPoints); // Calculating the number of steps

  // Helper function to calculate average and standard deviation
  function calculateStats(values) {
    const avg = values.reduce((sum, value) => sum + value, 0) / values.length;
    const stdDev = Math.sqrt(values.reduce((sum, value) => sum + Math.pow(value - avg, 2), 0) / values.length);
    return { avg, stdDev };
  }

  // Process each dataset to separate summary data and anomalies
  const processedDatasets = datasets.map(dataset => {
    const { avg, stdDev } = calculateStats(dataset.data);
    const outlierThreshold = avg + 2 * stdDev;

    // Generate summary data: take every nth point as a representative sample
    const summaryData = dataset.data.filter((value, index) => index % step === 0);
    const newLabels = labels.map(label => formatToLocalTime(label));
    // Identify anomalies and format their timestamps to local time
    const anomalies = dataset.data
      .map((value, index) => ({
        value,
        timestamp: formatToLocalTime(labels[index]) // Format timestamp to local time
      }))
      .filter(point => point.value > outlierThreshold);

    return {
      label: newLabels,
      summaryData,
      avg,
      anomalies
    };
  });

  // Create corresponding labels for summary data points
  const summaryLabels = labels.filter((_, index) => index % step === 0);

  return { labels: summaryLabels, datasets: processedDatasets };
}



function compressLatencyData(data, maxPoints) {
  const { datasets } = data;
  const totalPoints = datasets[0]?.data.length || 0; // Get the total points from the first dataset
  const step = Math.ceil(totalPoints / maxPoints); 

  function calculateStats(values) {
    const avg = values.reduce((sum, value) => sum + value, 0) / values.length;
    const stdDev = Math.sqrt(values.reduce((sum, value) => sum + Math.pow(value - avg, 2), 0) / values.length);
    return { avg, stdDev };
  }

  const processedDatasets = datasets.map(dataset => {
    // Extract `y` values for statistical calculations
    const yValues = dataset.data.map(point => point.y);
    const { avg, stdDev } = calculateStats(yValues);
    const outlierThreshold = avg + 2 * stdDev;

    // Generate summary data by sampling every nth point based on step
    const summaryData = dataset.data.filter((_, index) => index % step === 0);

    // Identify outliers and include their original `x` timestamps
    const anomalies = dataset.data
      .map((point) => ({
        x: point.x,
        y: point.y
      }))
      .filter(point => point.y > outlierThreshold);

    // Create summary with Latest, Avg, Max, Min, and Sample Points
    const summary = {
      latest: yValues[yValues.length - 1].toFixed(2), // Last value in yValues
      avg: avg.toFixed(2),
      max: Math.max(...yValues).toFixed(2),
      min: Math.min(...yValues).toFixed(2),
    };

    return {
      label: dataset.label,
      summaryData,
      summary,
      anomalies
    };
  });

  return { datasets: processedDatasets };
}

// this function is to dynamically choose the max points based on the refresh frequency chosen
function chooseMaxPoints(IntervalPeriod){
  switch(IntervalPeriod){
    case "15m":
      return 50;
    case "5m":
      return 75;
    case "1m":
      return 250;
    case "30s":
      return 500;
    case "10s":
      return 1000;
    case "5s":
      return 1000;
    default:
      return 50; 
  }
}




function summarizeDataset(dataset) {
  if (!dataset || !dataset.data || dataset.data.length === 0) {
    return { label: dataset?.label || "Unknown", summary: "No data available" };
  }

  const validData = dataset.data.filter(point => point && typeof point.y === 'number' && !isNaN(point.y));

  if (validData.length === 0) {
    return { label: dataset.label, summary: "No valid data points" };
  }

  const latest = validData[validData.length - 1].y;
  const sum = validData.reduce((acc, point) => acc + point.y, 0);
  const avg = sum / validData.length;
  const max = Math.max(...validData.map(point => point.y));
  const min = Math.min(...validData.map(point => point.y));

  return {
    label: dataset.label,
    summary: `Latest: ${latest.toFixed(2)}, Avg: ${avg.toFixed(2)}, Max: ${max.toFixed(2)}, Min: ${min.toFixed(2)}`,
    samplePoints: validData.slice(-5).map(point => ({ x: point.x, y: point.y.toFixed(2) }))
  };
}

function prepareDateForOpenAI(data) {
  if (data && Array.isArray(data.datasets)) {
    return data.datasets.map(summarizeDataset);
  }
  return "No data available or unexpected format";
}



export async function generateReportWithOpenAI(reportData) {
  const { agentId, customer_id, timeFrame, refreshFrequency, chartData, appNetChartData, throughputChartData, lifbeChartData } = reportData;

   // Fetch hardware information directly here
  

   

   

  const {hardware, networkType, expextedAppLatency, expectedStability, expectedPacketLoss} = await fetchHardwareAndNetworkType(agentId, customer_id);

  const languageMap = {
    en: "English",
    fr: "French",
    es: "Spanish",
    swe: "Swedish",
    tc: "Traditional Chinesse",
    ja: "Japanese using a mix of Kanji, Hiragana and Katakana as appropriate"
  };

  
  const language = languageMap[i18n.language];
  const CompressedChartData = compressLatencyData(chartData, chooseMaxPoints(refreshFrequency));
  const compressedAppData = compressData(appNetChartData, chooseMaxPoints(refreshFrequency));
  const preparedThroughputChartData = prepareDateForOpenAI(throughputChartData);
  const preparedLifbeChartData = prepareDateForOpenAI(lifbeChartData);

  const AverageApplicationLatency = compressedAppData.datasets[0].avg;
 

  const prompt = `Generate a detailed network performance analysis report based on the following data:

Agent ID: ${agentId}
Time Frame: ${timeFrame}
Refresh Frequency: ${refreshFrequency}
Hardware Used: ${hardware}
Network type Used: ${networkType}
Expected Application Latency: ${expextedAppLatency}
Expected Connectivity Stability: ${expectedStability}
Expected Connectivity Stability: ${expectedPacketLoss}
Average Application Latency: ${AverageApplicationLatency}

Protocol Latency Data:
${JSON.stringify(CompressedChartData, null, 2)}

Application and Network Latency Data:
${JSON.stringify(compressedAppData, null, 2)}

Throughput Data:
${JSON.stringify(preparedThroughputChartData, null, 2)}

LIFBE Throughput Data:
${JSON.stringify(preparedLifbeChartData, null, 2)}

Please structure your report as follows, ensuring each section is detailed and data-driven. Also make sure to 
turn all time formats into more human readable format by just  (ex: ""2024-11-14T07:15:00.000Z"" to  7:15 PM) do not convert it to utc, simply take the time label given and only take the time
Also take into account the hardware type and network type when trying to diagnose problems (if network type is not 3g, 4g, 5g, wi-fi or lte or other common network types, do not take them into account):

# Network Performance Analysis Report

## Executive Summary
Provide a concise overview of the network performance, highlighting key findings and trends.

## Application and Network Latency Analysis
- Detailed analysis of Application and Network Latency
- Comparison between Application and Network Latency
- Trends and patterns observed
- Potential impact on overall network performance

## Protocol Latency Analysis
- Breakdown of latency for each protocol (HTTP, HTTPS, TCP, UDP, ICMP, TWAMP)
- Comparative analysis between different protocols
- Identification of protocols with highest and lowest latencies
- Potential reasons for latency differences

## Throughput Performance
- Analysis of TCP and UDP throughput (download and upload)
- LIFBE throughput analysis
- Comparison between different throughput metrics
- Identification of any bottlenecks or performance issues

## Service Level Monitoring
- Highlight the average application latency and compare it to expected benchmarks for the given network type and hardware please mention the hardware and network if needed.
- Discuss any significant deviations from the benchmarks and their potential impact on service performance, please mention the impact.
- Identify patterns or trends over the observed time frame that indicate consistent latency issues or fluctuations.

## Key Observations
List at least 5 significant observations from the data, considering all metrics. If appropriate reference the data to prove the observations
make the observations detailed and specific

## Notable Performance Insights
Provide at least 3 insights derived from the throughput and latency data. If appropriate reference the data to prove the Insights
make the observations detailed and specific

## Recommendations for Improving Network Performance
Provide 4-5 detailed and specific recommendations to improve network performance based on the analysis. For each recommendation:

Identify the issue by referencing specific data points that indicate poor performance or abnormal behavior (e.g., latency spikes, bandwidth limitations, or packet loss).
Explain why this issue is problematic, highlighting its impact on network performance, application reliability, or user experience.
Propose actionable steps to address the issue, tailored to the environment, such as the type of network, hardware configurations, or software being used.
Consider factors like hardware limitations, network topology, or external conditions (e.g., congestion, interference) that might contribute to the issue.
Where applicable, recommend specific tools, configurations, or industry best practices to monitor, diagnose, or resolve the problem.
Ensure that the recommendations are thorough, actionable, and grounded in the provided data.

## Conclusion
Summarize the overall network performance and the potential impact of implementing the recommendations.

Please ensure that your analysis is data-driven, referencing specific values and trends from the provided datasets. Use markdown formatting (USE ** ** TO MAKE SUBHEADING NOT ###) and try to make key points in bold. 
Please responsed in ${language}`;

  try {
    
    const response = await openai.chat.completions.create({
      model:  "ft:gpt-4o-2024-08-06:latencetech:saas-model5:ANlI1JmB", 
      messages: [
        { role: "system", content: "You are an expert network performance analyst. Generate a comprehensive, detailed report based on the provided data, ensuring all sections are thoroughly addressed with specific references to the data. Make sure to use data to reference your analysis as much as possible" },
        { role: "user", content: prompt }
      ],
      max_tokens: 8000,
      temperature: 0.7 ,
      frequency_penalty: 0,
      presence_penalty: 0,
      response_format: { type: "text" },
    });
    var responseAI = response.choices[0].message.content.trim();

    return {
      responseAI,
      expextedAppLatency,
      AverageApplicationLatency,
      expectedStability,
      expectedPacketLoss
    }
  } catch (error) {
    console.error('Error generating report with OpenAI:', error);
    return 'Error generating report. Please try again later.';
  } 
   
}