import React, { useState, useCallback, useRef } from 'react';
import './Report.css';
import { Box, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel, Paper, CircularProgress } from '@mui/material';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale';
import generateEnhancedPDF from './generateEnhancedPDF';
import { generateReportWithOpenAI } from './openaiInteraction';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';

 
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const Report = () => {
  const { auth: { customer_id } } = useAuth();
  const { t } = useTranslation();
  const [agentId, setAgentId] = useState('1');
  const [timeFrame, setTimeFrame] = useState('24h');
  const [refreshFrequency, setRefreshFrequency] = useState('15m');
  const [reportTitle, setReportTitle] = useState('');
  const [reportGenerated, setReportGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const appNetChartRef = useRef(null);
  const throughputChartRef = useRef(null);
  const lifbeChartRef = useRef(null);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: []
  });
  const [appNetChartData, setAppNetChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Application Latency',
        data: [],
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: 'Network Latency',
        data: [],
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.2)',
      }
    ]
  });
  const [throughputChartData, setThroughputChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'TCP Download',
        data: [],
        borderColor: 'rgb(143, 59, 184)',
        backgroundColor: 'rgba(143, 59, 184, 0.2)',
      },
      {
        label: 'TCP Upload',
        data: [],
        borderColor: 'rgb(222, 182, 242)',
        backgroundColor: 'rgba(222, 182, 242, 0.2)',
      },
      {
        label: 'UDP Download',
        data: [],
        borderColor: 'rgb(31, 96, 196)',
        backgroundColor: 'rgba(31, 96, 196, 0.2)',
      },
      {
        label: 'UDP Upload',
        data: [],
        borderColor: 'rgb(192, 216, 255)',
        backgroundColor: 'rgba(192, 216, 255, 0.2)',
      }
    ]
  });
  const [lifbeChartData, setLifbeChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'LIFBE Download Throughput',
        data: [],
        borderColor: 'rgb(143, 59, 184)',
        backgroundColor: 'rgba(143, 59, 184, 0.2)',
      },
      {
        label: 'LIFBE Upload Throughput',
        data: [],
        borderColor: 'rgb(222, 182, 242)',
        backgroundColor: 'rgba(222, 182, 242, 0.2)',
      }
    ]
  });
  const [openAIReport, setOpenAIReport] = useState('');
  const [AverageAppLatency, setAverageAppLatency] = useState('');
  const [ExpectedAppLatency, setExpectedAppLatency] = useState('');
  const [ExpectedNetStab, setExpectedNetStab] = useState('');
  const [ExpectedPacketLoss, setExpectedPacketLoss] = useState('');




  const [selectedCharts, setSelectedCharts] = useState([]);
  const intervalIdRef = useRef(null);
  const chartRef = useRef(null);

  const handleAgentIdChange = (e) => setAgentId(e.target.value);
  const handleTimeFrameChange = (e) => setTimeFrame(e.target.value);
  const handleRefreshFrequencyChange = (e) => setRefreshFrequency(e.target.value);
  const handleReportTitleChange = (e) => setReportTitle(e.target.value);

  const API_URL = `${process.env.REACT_APP_INFLUX_API}`;
  const HEADERS = {
    'Authorization': `Token ${process.env.REACT_APP_INFLUX_TOKEN}`,
    'Accept': 'application/csv',
    'Content-type': 'application/vnd.flux'
  };

  const fetchProtocolLatency = useCallback(async (protocol) => {
    const query = `
      from(bucket: "LatenceTech")
        |> range(start: -${timeFrame})
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "${protocol}_result")
        |> filter(fn: (r) => r["_field"] == "delay")
        |> aggregateWindow(every: ${refreshFrequency}, fn: mean)
        |> keep(columns: ["_time", "_value"])
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        return lines.map(line => {
          const [, , , time, value] = line.split(',');
          return { x: new Date(time), y: parseFloat(value) };
        });
      } else {
        console.error(`Error fetching ${protocol} latency data: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching ${protocol} latency data:`, error);
      return [];
    }
  }, [agentId, timeFrame, refreshFrequency]);

  const fetchApplicationLatency = useCallback(async () => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${timeFrame})
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "http_result" or r["_measurement"] == "https_result" or r["_measurement"] == "tcp_result" or r["_measurement"] == "udp_result")
        |> filter(fn: (r) => r["_field"] == "delay")
        |> keep(columns: ["_measurement", "_time", "_value"])
        |> aggregateWindow(every: ${refreshFrequency}, fn: mean, createEmpty: false)
        |> rename(columns: {_value: "Application"})
        |> keep(columns: ["_time", "Application"])
        |> yield(name: "Application")
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        return lines.map(line => {
          const parts = line.split(',');
          return { time: parts[3], value: parseFloat(parts[4]) };
        });
      } else {
        console.error(`Error fetching application latency data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching application latency data from InfluxDB:`, error);
      return [];
    }
  }, [agentId, timeFrame, refreshFrequency]);

  const fetchNetworkLatency = useCallback(async () => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${timeFrame})
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "twamp_result" or r["_measurement"] == "icmp_result")
        |> filter(fn: (r) => r["_field"] == "delay")
        |> keep(columns: ["_measurement", "_time", "_value"])
        |> aggregateWindow(every: ${refreshFrequency}, fn: mean, createEmpty: false)
        |> rename(columns: {_value: "Network"})
        |> keep(columns: ["_time", "Network"])
        |> yield(name: "mean")
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1); // Skip header line
        return lines.map(line => {
          const parts = line.split(',');
          return { time: parts[3], value: parseFloat(parts[4]) };
        });
        
      } else {
        console.error(`Error fetching network latency data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching network latency data from InfluxDB:`, error);
      return [];
    }
  }, [agentId, timeFrame, refreshFrequency]);

  const fetchThroughput = useCallback(async (protocol, direction) => {
    const query = `
      import "regexp"
      from(bucket: "LatenceTech")
        |> range(start: -${timeFrame})
        |> filter(fn: (r) => r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> filter(fn: (r) => r["_measurement"] == "iperf_${protocol}_result")
        |> filter(fn: (r) => r["_field"] == "${direction}BPS")
        |> keep(columns: ["_time", "_value"])
        |> aggregateWindow(every: ${refreshFrequency}, fn: mean, createEmpty: false)
        |> rename(columns: {_value: "${protocol.toUpperCase()} ${direction.charAt(0).toUpperCase() + direction.slice(1)} Throughput"})
        |> keep(columns: ["_time", "${protocol.toUpperCase()} ${direction.charAt(0).toUpperCase() + direction.slice(1)} Throughput"])
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1);
        return lines.map(line => {
          const parts = line.split(',');
          return { x: new Date(parts[3]), y: parseFloat(parts[4]) };
        });
      } else {
        console.error(`Error fetching ${protocol.toUpperCase()} ${direction} throughput data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching ${protocol.toUpperCase()} ${direction} throughput data from InfluxDB:`, error);
      return [];
    }
  }, [agentId, timeFrame, refreshFrequency]);

  const fetchLifbeData = useCallback(async (direction) => {
    const query = `
      from(bucket: "LatenceTech")
        |> range(start: -${timeFrame})
        |> filter(fn: (r) => r._measurement == "lifbe_result" and r._field == "${direction}MBPS" and r.agentID == "${agentId}" and
          r.customerID == "${customer_id}")
        |> keep(columns: ["_time", "_value"])
        |> sort(columns: ["_time"])
    `;

    try {
      const response = await fetch(API_URL, {
        method: 'POST',
        headers: HEADERS,
        body: query
      });

      if (response.ok) {
        const csvData = await response.text();
        const lines = csvData.trim().split('\n').slice(1);
        return lines.map(line => {
          const parts = line.split(',');
          return { x: new Date(parts[3]), y: parseFloat(parts[4]) };
        });
      } else {
        console.error(`Error fetching ${direction} LIFBE data from InfluxDB: ${response.status} ${response.statusText}`);
        return [];
      }
    } catch (error) {
      console.error(`Error fetching ${direction} LIFBE data from InfluxDB:`, error);
      return [];
    }
  }, [agentId, timeFrame]);

  const fetchData = useCallback(async () => {
    const protocols = ['http', 'https', 'tcp', 'udp', 'icmp', 'twamp'];
    const colors = ['rgb(255, 165, 0)', 'rgb(143, 59, 184)', 'rgb(138, 184, 255)', 'rgb(0, 84, 174)', 'rgb(115, 191, 105)', 'rgb(250, 222, 42)'];

    const allData = await Promise.all(protocols.map(fetchProtocolLatency));

    const datasets = protocols.map((protocol, index) => ({
      label: `${protocol.toUpperCase()} Latency`,
      data: allData[index],
      borderColor: colors[index],
      backgroundColor: colors[index],
      pointRadius: 1,
      borderWidth: 2,
      tension: 0.4,
      cubicInterpolationMode: 'monotone',
    }));

    setChartData({ datasets });
  }, [fetchProtocolLatency]);

  
  const updateAppNetChartData = useCallback((appLatency, netLatency) => {
    
    const labels = [...new Set([...appLatency.map(d => d.time), ...netLatency.map(d => d.time)])].sort();
    setAppNetChartData({
      labels,
      datasets: [
        {
          label: 'Application Latency',
          data: labels.map(label => {
            const point = appLatency.find(d => d.time === label);
            return point ? point.value : null;
          }),
          borderColor: 'rgb(75, 192, 192)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          pointRadius: 1,
          fill: false,
          tension: 0.4,
          borderWidth: 2,
          cubicInterpolationMode: 'monotone'
        },
        {
          label: 'Network Latency',
          data: labels.map(label => {
            const point = netLatency.find(d => d.time === label);
            return point ? point.value : null;
          }),
          borderColor: 'rgb(53, 162, 235)',
          backgroundColor: 'rgba(53, 162, 235, 0.2)',
          pointRadius: 1,
          fill: false,
          tension: 0.4,
          borderWidth: 2,
          cubicInterpolationMode: 'monotone',
        },
      ],
    });
  }, []);


  const updateThroughputChartData = useCallback((tcpDownload, tcpUpload, udpDownload, udpUpload) => {
    setThroughputChartData({
      datasets: [
        {
          label: 'TCP Download',
          data: tcpDownload,
          borderColor: 'rgb(143, 59, 184)',
          backgroundColor: 'rgba(143, 59, 184, 0.2)',
          pointRadius: 1,
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'TCP Upload',
          data: tcpUpload,
          borderColor: 'rgb(222, 182, 242)',
          backgroundColor: 'rgba(222, 182, 242, 0.2)',
          pointRadius: 1,
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'UDP Download',
          data: udpDownload,
          borderColor: 'rgb(31, 96, 196)',
          backgroundColor: 'rgba(31, 96, 196, 0.2)',
          pointRadius: 1,
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'UDP Upload',
          data: udpUpload,
          borderColor: 'rgb(192, 216, 255)',
          backgroundColor: 'rgba(192, 216, 255, 0.2)',
          pointRadius: 1,
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          cubicInterpolationMode: 'monotone',
        }
      ]
    });
  }, []);

  const updateLifbeChartData = useCallback((downloadData, uploadData) => {
    setLifbeChartData({
      datasets: [
        {
          label: 'LIFBE Download Throughput',
          data: downloadData,
          borderColor: 'rgb(143, 59, 184)',
          backgroundColor: 'rgba(143, 59, 184, 0.2)',
          pointRadius: 1,
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          cubicInterpolationMode: 'monotone',
        },
        {
          label: 'LIFBE Upload Throughput',
          data: uploadData,
          borderColor: 'rgb(222, 182, 242)',
          backgroundColor: 'rgba(222, 182, 242, 0.2)',
          pointRadius: 1,
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          cubicInterpolationMode: 'monotone',
        }
      ]
    });
  }, []);

  // const handleGenerateReport = useCallback(async () => {
  //   setIsLoading(true);
  //   await fetchData();
  //   setReportGenerated(true);

  //   const [appLatency, netLatency] = await Promise.all([
  //     fetchApplicationLatency(),
  //     fetchNetworkLatency()
  //   ]);
  //   updateAppNetChartData(appLatency, netLatency);

  //   const [tcpDownload, tcpUpload, udpDownload, udpUpload] = await Promise.all([
  //     fetchThroughput('tcp', 'receiver'),
  //     fetchThroughput('tcp', 'sender'),
  //     fetchThroughput('udp', 'receiver'),
  //     fetchThroughput('udp', 'sender')
  //   ]);
  //   updateThroughputChartData(tcpDownload, tcpUpload, udpDownload, udpUpload);

  //   const [downloadData, uploadData] = await Promise.all([
  //     fetchLifbeData('receiver'),
  //     fetchLifbeData('sender')
  //   ]);
  //   updateLifbeChartData(downloadData, uploadData);

  //   // Generate report with OpenAI
  //   const openAIReportData = {
  //     agentId,
  //     timeFrame,
  //     refreshFrequency,
  //     chartData,
  //     appNetChartData,
  //     throughputChartData,
  //     lifbeChartData
  //   };

  //   try {
  //     const generatedReport = await generateReportWithOpenAI(openAIReportData);
  //     setOpenAIReport(generatedReport);
  //   } catch (error) {
  //     console.error('Failed to generate report with OpenAI:', error);
  //     const fallbackReport = generateFallbackReport(chartData, appNetChartData, throughputChartData, lifbeChartData);
  //     setOpenAIReport(fallbackReport);
  //   }

  //   setIsLoading(false);

  //   // Set up new interval for auto-refresh
  //   const refreshInterval = getRefreshInterval(refreshFrequency);
  //   if (intervalIdRef.current) {
  //     clearInterval(intervalIdRef.current);
  //   }
  //   intervalIdRef.current = setInterval(async () => {
  //     await fetchData();
  //     const [appLatency, netLatency] = await Promise.all([
  //       fetchApplicationLatency(),
  //       fetchNetworkLatency()
  //     ]);
  //     updateAppNetChartData(appLatency, netLatency);

  //     const [tcpDownload, tcpUpload, udpDownload, udpUpload] = await Promise.all([
  //       fetchThroughput('tcp', 'receiver'),
  //       fetchThroughput('tcp', 'sender'),
  //       fetchThroughput('udp', 'receiver'),
  //       fetchThroughput('udp', 'sender')
  //     ]);
  //     updateThroughputChartData(tcpDownload, tcpUpload, udpDownload, udpUpload);

  //     const [downloadData, uploadData] = await Promise.all([
  //       fetchLifbeData('receiver'),
  //       fetchLifbeData('sender')
  //     ]);
  //     updateLifbeChartData(downloadData, uploadData);
  //   }, refreshInterval);


  // }, [fetchData, fetchApplicationLatency, fetchNetworkLatency, fetchThroughput, fetchLifbeData,
  //   updateAppNetChartData, updateThroughputChartData, updateLifbeChartData,
  //   agentId, timeFrame, refreshFrequency,
  //   chartData, appNetChartData, throughputChartData, lifbeChartData]);

  const handleGenerateReportChart = useCallback(async () => {
    setIsLoading(true);
    await fetchData();
    setReportGenerated(true);

    const [appLatency, netLatency] = await Promise.all([
      fetchApplicationLatency(),
      fetchNetworkLatency()
    ]);
    updateAppNetChartData(appLatency, netLatency);

    const [tcpDownload, tcpUpload, udpDownload, udpUpload] = await Promise.all([
      fetchThroughput('tcp', 'receiver'),
      fetchThroughput('tcp', 'sender'),
      fetchThroughput('udp', 'receiver'),
      fetchThroughput('udp', 'sender')
    ]);
    updateThroughputChartData(tcpDownload, tcpUpload, udpDownload, udpUpload);

    const [downloadData, uploadData] = await Promise.all([
      fetchLifbeData('receiver'),
      fetchLifbeData('sender')
    ]);
    updateLifbeChartData(downloadData, uploadData);

    setIsLoading(false);

    // Set up new interval for auto-refresh
    // const refreshInterval = getRefreshInterval(refreshFrequency);
    // if (intervalIdRef.current) {
    //   clearInterval(intervalIdRef.current);
    // }
    // intervalIdRef.current = setInterval(handleGenerateReportChart, refreshInterval);
  }, [fetchData, fetchApplicationLatency, fetchNetworkLatency, fetchThroughput, fetchLifbeData,
    updateAppNetChartData, updateThroughputChartData, updateLifbeChartData,
    agentId, timeFrame, refreshFrequency]);

    function convertLabelsToLocal(appNetChartData) {
      // Clone the original object to avoid mutating it
      const updatedData = { ...appNetChartData };
    
      // Convert each label to local time and keep it in ISO format
      updatedData.labels = appNetChartData.labels.map(label => {
        const utcDate = new Date(label); // Convert label to a Date object in UTC
        const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000); // Adjust to local time
        return localDate.toISOString(); // Convert back to ISO format
      });
    
      return updatedData;
    }
    
    // Usage
    //const localAppNetChartData = convertLabelsToLocal(appNetChartData);
    //console.log("Converted Labels to Local Time (ISO Format):", localAppNetChartData.labels);
    

    const newData = convertLabelsToLocal(appNetChartData)
   // console.log("OLD DATA" + appNetChartData.labels)
   // console.log(newData.labels)
  const handleGenerateReportText = useCallback(async () => {
    setIsLoading(true);
    const openAIReportData = {
      agentId,
      customer_id,
      timeFrame,
      refreshFrequency,
      chartData,
      appNetChartData,
      throughputChartData,
      lifbeChartData
    };

    try {
      const generatedReport = await generateReportWithOpenAI(openAIReportData);
      setOpenAIReport(generatedReport.responseAI);
      setAverageAppLatency(generatedReport.AverageApplicationLatency)
      setExpectedAppLatency(generatedReport.expextedAppLatency)
      setExpectedNetStab(generatedReport.expectedStability)
      setExpectedPacketLoss(generatedReport.expectedPacketLoss)
    } catch (error) {
      console.error('Failed to generate report with OpenAI:', error);
      const fallbackReport = generateFallbackReport(chartData, appNetChartData, throughputChartData, lifbeChartData);
      setOpenAIReport(fallbackReport);
    }
    setIsLoading(false);
  }, [agentId, timeFrame, refreshFrequency, chartData, newData, throughputChartData, lifbeChartData]);


  const handleGeneratePDF = async () => {
    const fileName = prompt('Enter the name for the file:');
    if (!fileName) return;
  
    const chartInstance = chartRef.current;
    const appNetChartInstance = appNetChartRef.current;
    const throughputChartInstance = throughputChartRef.current;
    const lifbeChartInstance = lifbeChartRef.current;
  
    try {
      // Wait a moment for chart updates to take effect
      await new Promise((resolve) => setTimeout(resolve, 500)); // Adjust delay if needed
  
      const pdf = await generateEnhancedPDF(
        reportTitle,
        agentId,
        chartData,
        chartInstance,
        timeFrame,
        refreshFrequency,
        openAIReport,
        AverageAppLatency,
        ExpectedAppLatency,
        ExpectedNetStab,
        ExpectedPacketLoss,
        { datasets: appNetChartData.datasets, chartInstance: appNetChartInstance },
        { datasets: throughputChartData.datasets, chartInstance: throughputChartInstance },
        { datasets: lifbeChartData.datasets, chartInstance: lifbeChartInstance }
      );
  
      pdf.save(`${fileName}.pdf`);
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  

  const handleChartSelection = (event) => {
    setSelectedCharts(event.target.value);
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
          tooltipFormat: 'yyyy-MM-dd HH:mm:ss',
          displayFormats: {
            minute: 'HH:mm'
          }
        },
        adapters: {
          date: {
            locale: enUS,
          },
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          maxTicksLimit: 10
        },
      },
      y: {
        // beginAtZero: true,
        title: {
          display: true,
          text: 'Value',
        }
      },
    },
    plugins: {
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      legend: {
        position: 'top',
      },
    },
  };

  // console.log('Chart Data:', JSON.stringify({
  //   appNetChartData,
  // }, null, 2));

  const generateFallbackReport = (chartData, appNetChartData, throughputChartData, lifbeChartData) => {
    const getLatestAndAverage = (dataset) => {
      if (!dataset || !dataset.data || dataset.data.length === 0) {
        return { latest: 'N/A', average: 'N/A' };
      }

      let validData = dataset.data.filter(point => point && typeof point.y === 'number' && !isNaN(point.y))
        .map(point => point.y);

      if (validData.length === 0) {
        return { latest: 'N/A', average: 'N/A' };
      }

      const latest = validData[validData.length - 1].toFixed(2);
      const average = (validData.reduce((sum, value) => sum + value, 0) / validData.length).toFixed(2);

      return { latest, average };
    };   

    const generateSummary = (data, title) => {
      if (!data || !data.datasets || data.datasets.length === 0) {
        return `\n## ${title}\nNo data available.`;
      }

      return `\n## ${title}\n` + data.datasets.map(dataset => {
        if (!dataset || typeof dataset !== 'object') {
          return `Invalid dataset`;
        }
        const { latest, average } = getLatestAndAverage(dataset);
        return `${dataset.label || 'Unlabeled Dataset'}: Latest - ${latest}, Average - ${average}`;
      }).join('\n');
    };

    const protocolSummary = generateSummary(chartData, "Protocol Latency Analysis");
    const appNetSummary = generateSummary(appNetChartData, "Application and Network Latency");
    const throughputSummary = generateSummary(throughputChartData, "Throughput Analysis");
    const lifbeSummary = generateSummary(lifbeChartData, "LIFBE Analysis");

    return `
  # Latency Report

  ## Executive Summary
  This report provides an overview of latency measurements for various protocols and metrics.

  ${protocolSummary}

  ${appNetSummary}

  ${throughputSummary}

  ${lifbeSummary}

  ## Recommendations
  1. Monitor protocols and metrics with consistently high values.
  2. Investigate any sudden spikes in latency or throughput.
  3. Consider optimizing network infrastructure for protocols or metrics with higher average values.

  ## Conclusion
  Regular monitoring and analysis of latency and throughput data across different protocols can help maintain optimal network performance.
    `;
  };


  return (
    <Box className="report">
      <Typography variant="h3" className="page-heading">{t('dataReports')}</Typography>

      {/* Report Form */}
      <Box className="report-form">
        <TextField
          variant="outlined"
          label={t('agentId')}
          value={agentId}
          onChange={handleAgentIdChange}
          placeholder={t('agentId')}
          fullWidth
          margin="normal"
          className="white-input"
        />

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel>{t('timeFrame')}</InputLabel>
          <Select
            value={timeFrame}
            onChange={handleTimeFrameChange}
            label={t('timeFrame')}
            className="white-input"
            style={{ border: '1px solid #ffffff', backgroundColor: '#323769', marginTop: '10px' }}
          >
            <MenuItem value="5m">{t('5minutes')}</MenuItem>
            <MenuItem value="15m">{t('15minutes')}</MenuItem>
            <MenuItem value="30m">{t('30minutes')}</MenuItem>
            <MenuItem value="1h">{t('1hour')}</MenuItem>
            <MenuItem value="6h">{t('6hours')}</MenuItem>
            <MenuItem value="12h">{t('12hours')}</MenuItem>
            <MenuItem value="24h">{t('24hours')}</MenuItem>
          </Select>
        </FormControl>

        <FormControl variant="outlined" fullWidth margin="normal">
          <InputLabel>{t('refreshFrequency')}</InputLabel>
          <Select
            value={refreshFrequency}
            onChange={handleRefreshFrequencyChange}
            label={t('refreshFrequency')}
            className="white-input"
            style={{ border: '1px solid #ffffff', backgroundColor: '#323769', marginTop: '10px' }}
          >
            <MenuItem value="5s">{t('5seconds')}</MenuItem>
            <MenuItem value="10s">{t('10seconds')}</MenuItem>
            <MenuItem value="30s">{t('30seconds')}</MenuItem>
            <MenuItem value="1m">{t('1minute')}</MenuItem>
            <MenuItem value="5m">{t('5minutes')}</MenuItem>
            <MenuItem value="15m">{t('15minutes')}</MenuItem>
          </Select>
        </FormControl>

        <TextField
          variant="outlined"
          label={t('reportTitle')}
          value={reportTitle}
          onChange={handleReportTitleChange}
          placeholder={t('reportTitle')}
          fullWidth
          margin="normal"
          className="white-input"
        />

        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateReportChart}
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? t('generatingReport') : t('generateReport')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateReportText}
          fullWidth
          disabled={isLoading}
        >
          {isLoading ? t('generatingReport') : t('generateReportText')}
        </Button>
      </Box>


      {/* Report Output */}
      {reportGenerated && (
        <Box className="report-output">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGeneratePDF}
            disabled={isLoading}
          >
            {t('generatePDF')}
          </Button>

          <Box id="report-content" className="report-content-scrollable">
            <Typography variant="h6">
              {t('date')}: {new Date().toLocaleDateString()}
            </Typography>
            <Typography variant="h6">
              {t('agent')}: {agentId}
            </Typography>
            <Typography variant="h4">
              {reportTitle || t('latencyReport')}
            </Typography>

            {/* Main Latency Chart */}
            <Paper className="report-chart">
              <Typography variant="h5">{t('latencyHistory')}</Typography>
              <div style={{ height: '400px', width: '100%' }}>
                <Line data={chartData} options={chartOptions} ref={chartRef} />
              </div>
            </Paper>

            {/* Application and Network Latency Chart */}
            <Paper className="report-chart">
              <Typography variant="h5">{t('appNetworkLatency')}</Typography>
              <div className="chart-container" style={{ height: '300px', width: '100%' }}>
                <Line data={appNetChartData} options={chartOptions} ref={appNetChartRef} />
              </div>
            </Paper>

            {/* Throughput Chart */}
            <Paper className="report-chart">
              <Typography variant="h5">{t('throughputHistory')}</Typography>
              <div className="chart-container" style={{ height: '300px', width: '100%' }}>
                <Line data={throughputChartData} options={chartOptions} ref={throughputChartRef} />
              </div>
            </Paper>

            {/* LIFBE Chart */}
            <Paper className="report-chart">
              <Typography variant="h5">{t('lifbeThroughputHistory')}</Typography>
              <div className="chart-container" style={{ height: '300px', width: '100%' }}>
                <Line data={lifbeChartData} options={chartOptions} ref={lifbeChartRef} />
              </div>
            </Paper>
          </Box>

          <Typography variant="h5" component="pre" className="report-analysis">
            {t('analysis')}
          </Typography>
          {isLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
              <CircularProgress />
            </Box>
          ) : (
            <pre>{openAIReport}</pre>
          )}
        </Box>
      )}
    </Box>
  );
};

export default Report;